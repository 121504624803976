import React, { Component } from 'react'
import Image from './Image';

class ViewProduct extends Component {
  render() {
    const parent = this.props.this;
    const viewProduct = this.props.viewProduct;
    const qtyOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    return (
        <div className="product-list">
            <h2>More Info</h2>
            <Image type="view" brand={viewProduct.brand} name={viewProduct.productName} flavor={parent.state[`${viewProduct.brand} ${viewProduct.productName} flavor`]}/>
            <div className="view-info-ctr">
            <p><b>{viewProduct.brand}</b> {viewProduct.productName}</p>
            <p style={{color: "#51AE00"}}><b>{(viewProduct.price).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })}</b></p>
            {/* <div className="view-info">
                <p>Flavors
                <select className="flavor-select" onChange={e => parent.handleSelectorChange(e, viewProduct)} value={parent.state[`${viewProduct.brand} ${viewProduct.productName} flavor`]}>
                    {viewProduct.flavors.map((flavor, i) => (
                    <option key={i} value={flavor.name}>
                        {flavor.name}
                    </option>
                    ))}
                </select>
                Qty
                <select className="qty-select" onChange={e => parent.handleQtySelectorChange(e, viewProduct)} value={parent.state[`${viewProduct.brand} ${viewProduct.productName}`]}>
                    {qtyOptions.map((val, i) => (
                    <option key={i} value={val}>
                        {val}
                    </option>
                    ))}
                </select>                
                </p>
            </div> */}

            <div className="product-view-info">
                  <p>Flavors
                    <select className="flavor-select" value={parent.state[`${viewProduct.brand} ${viewProduct.productName} flavor`]} onChange={e => parent.handleSelectorChange(e, viewProduct)}>
                      {viewProduct.flavors.map((flavor, i) => (
                        <option key={i} value={flavor.name}>
                          {flavor.name}
                        </option>
                      ))}
                    </select>
                    Qty
                    <select className="qty-select" value={parent.state[`${viewProduct.brand} ${viewProduct.productName}`]} onChange={e => parent.handleQtySelectorChange(e, viewProduct)}>
                      {qtyOptions.map((val, i) => (
                        <option key={i} value={val}>
                          {val}
                        </option>
                      ))}
                    </select>                
                  </p>
                </div>

            <div className="view-desc">
                <p>{viewProduct.description1}</p>
                <p>{viewProduct.description2}</p>
                <p>{viewProduct.description3}</p>
                <p>{viewProduct.description4}</p>
                <p>{viewProduct.description5}</p>
            </div>
            <div className="card-btn-ctr">
                <button onClick={e => parent.addToCart(e, viewProduct)} disabled={(parent.state[`${viewProduct.brand} ${viewProduct.productName}`] === undefined || parent.state[`${viewProduct.brand} ${viewProduct.productName}`] === "0" || parent.state[`${viewProduct.brand} ${viewProduct.productName} flavor`] === "Select a Flavor")} className="card-btn add-to-cart">Add to Cart</button>
                <button onClick={e => parent.exitViewProduct(e)} className="card-btn view-product" type="submit">Go Back</button>
            </div>        
            </div>
        </div>
    )
  }
}

export default ViewProduct