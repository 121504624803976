import React, { Component } from 'react';
import "../styles/CartAlert.css";

class CartAlert extends Component {
  render() {
    return (
        <div className="cart-alert">
            <p>{this.props.message}</p>
        </div>
    )
  }
}

export default CartAlert