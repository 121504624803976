import React, { Component } from 'react';
import "../styles/ToS.css";

class ToS extends Component {
    state = {
        confirmAge: false,
        viewToS: false,
    }

    continueToSite(e) {
        e.preventDefault();
        const parent = this.props.this;

        parent.setState({
            isAgeVerified: true,
        })
    }

    handleCheckbox(e) {
        this.setState({
            confirmAge: e.target.checked,
        })
    }

    toggleToS(e) {
        e.preventDefault();

        this.setState({
            viewToS: !this.state.viewToS
        })
    }

    render() {
        return (
        <div className="tos-ctr">
            <h2>Terms of Service</h2>
            <p>You must be 21 or older to view our website. Please read our Terms of Service and Privacy Policy before proceeding.</p>
            {!this.state.viewToS ? <div className="tos-btn-ctr">
                <button onClick={e => this.toggleToS(e)} className="tos-view-btn">View Terms of Service</button>
            </div> : 
            <div className="tos-scroll">    
                <p>TERMS OF SERVICE</p>
                <p>This Terms of Service agreement ("Agreement") is entered into between Sticksofnics ("Company") and the user ("User") accessing or using the Company's online vape shop ("Website"). By accessing or using the Website, User agrees to be bound by the terms and conditions set forth in this Agreement.</p>
                <p>Age Restriction</p>
                <p>User acknowledges and agrees that the Company strictly adheres to all applicable laws and regulations. Therefore, User must be at least 21 years of age or older to access and purchase products from the Website. By accessing or using the Website, User represents and warrants that they meet the age requirement and shall not attempt to purchase any vape products if they are under the age of 21. The Company reserves the right to request age verification from User and to refuse any transaction if the User fails to provide satisfactory proof of age.</p>
                <p>Acknowledgment of Risks</p>
                <p>User acknowledges and agrees that vaping nicotine products carries certain inherent risks. User acknowledges that vaping may cause adverse health effects, including but not limited to addiction, respiratory problems, cardiovascular issues, and potential harm to others through second-hand vapor exposure. User expressly assumes all risks associated with the use of nicotine vaping products and agrees that the Company shall not be held responsible for any such risks.</p>
                <p>Sales and Refunds</p>
                <p>All sales made through the Website are final. User understands and agrees that once an order is placed, it cannot be modified, canceled, or refunded, except as expressly provided herein or as required by applicable law. In the event of a defective product or an error on the part of the Company, User may request a refund or replacement within 14 days from the date of delivery, subject to the Company's sole discretion.</p>
                <p>Intellectual Property</p>
                <p>All content, trademarks, logos, and intellectual property displayed on the Website are the sole property of the Company or its licensors. User acknowledges and agrees not to use, reproduce, modify, or distribute any such materials without the prior written consent of the Company.</p>
                <p>Limitation of Liability</p>
                <p>To the maximum extent permitted by law, the Company shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or relating to the use or inability to use the Website, including but not limited to damages for loss of profits, data, or other intangible losses. User expressly agrees to indemnify and hold the Company harmless from any claims, liabilities, or expenses arising out of User's violation of this Agreement or any applicable laws.</p>
                <p>Governing Law and Jurisdiction</p>
                <p>This Agreement shall be governed by and construed in accordance with the laws of The United States of America. Any disputes arising out of or relating to this Agreement shall be subject to the exclusive jurisdiction of the courts of New York State.</p>
                <p>Severability</p>
                <p>If any provision of this Agreement is held to be invalid, illegal, or unenforceable, the remaining provisions shall remain in full force and effect.</p>
                <p>By accessing or using the Website, User acknowledges that they have read, understood, and agree to be bound by this Agreement.</p>
                <div className="checkbox-ctr">
                    <div className="checkbox">
                        <input type="checkbox" id="confirmAge" name="confirmAge" onChange={e => this.handleCheckbox(e)} />
                    </div>
                    <p>By checking this box and placing this order, I confirm that I am of legal age in the United States (21 and older) to purchase these products. I have no intent to distribute any product(s) to an individual who is not of legal age to purchase it themselves.
                    </p>
                </div>
                <button disabled={!this.state.confirmAge} onClick={e => this.continueToSite(e)} className="tos-view-btn">Continue to Store</button>
            </div>}
        </div>
    )
  }
}

export default ToS