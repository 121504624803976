import React, { Component } from 'react';
import Image from './Image';

class Card extends Component {
    render() {
        const qtyOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        const p = this.props.product;
        const parent = this.props.parent;

        return (
            <div className="product-card" key={`${p.brand}-${p.productName}`}>
                <Image type="card" brand={p.brand} name={p.productName} flavor={parent.state[`${p.brand} ${p.productName} flavor`]}/>
                <div className="card-name">
                    <p><b>{p.brand}</b> {p.productName}</p>
                </div>
                <div className="card-price">
                    <p><b>{(p.price).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    })}</b></p>
                </div>
                <div className="card-nic">
                    <p>{p.nicPercent}% Nicotine</p>
                </div>
                <div className="card-info">
                    <p>Flavors
                    <select className="flavor-select" onChange={e => parent.handleSelectorChange(e, p)} value={parent.state[`${p.brand} ${p.productName} flavor`]}>
                        {p.flavors.map((flavor, i) => (
                        <option key={i} value={flavor.name}>
                            {flavor.name}
                        </option>
                        ))}
                    </select>
                    Qty
                    <select className="qty-select" onChange={e => parent.handleQtySelectorChange(e, p)} value={parent.state[`${p.brand} ${p.productName}`]}>
                        {qtyOptions.map((val, i) => (
                        <option key={i} value={val}>
                            {val}
                        </option>
                        ))}
                    </select>
                    </p>
                </div>
                <div className="card-btn-ctr">
                    <button onClick={e => parent.addToCart(e, p)} disabled={(parent.state[`${p.brand} ${p.productName}`] === undefined || parent.state[`${p.brand} ${p.productName}`] === "0") || parent.state[`${p.brand} ${p.productName} flavor`] === "Select a Flavor"} className="card-btn add-to-cart">Add to Cart</button>
                    <button onClick={e => parent.viewProduct(e, p)} className="card-btn view-product" type="submit">More Info</button>
                </div>
                <div className="card-exit-ctr">
                    <button onClick={e => parent.closeProductsWindow(e)} className="card-exit-btn">Go Back</button>
                </div>
            </div>
        );
    }
}

export default Card