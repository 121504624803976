import React, { Component } from 'react';
import "../styles/StepCounter.css";

class StepCounter extends Component {
    getProgress() {
        if (this.props.isLoading) {
            return "80%";
        } else {
            switch(this.props.step) {
                case 1:
                    return "20%"
                case 2:
                    return "50%"
                case 3:
                    return "100%"
                default:
                    return "100%"
            }
        }
    }

    getTransition() {
        if (this.props.isLoading) {
            return "2.5s";
        } else {
            return "300ms";
        }
    }

    changeStep(e, step) {
        e.preventDefault();

        if (step < this.props.step && this.props.step === 2) {
            this.props.this.setState({
                step: step
            })
        }
    }
    
    render() {
        const step = this.props.step;
        
        return (
            <div className="counter-ctr">
                <div style={{width: this.getProgress(), transition: this.getTransition()}}className="step-slider"></div>
                <div className="step-slider-grey"></div>
                <div onClick={e => this.changeStep(e, 1)} className="step-ctr"><div className="step-circle"><h1>1</h1></div></div> 
                <div onClick={e => this.changeStep(e, 2)} className="step-ctr"><div className={step >= 2 ? "step-circle" : "step-circle step-disabled"}><h1>2</h1></div></div> 
                <div onClick={e => this.changeStep(e, 3)} className="step-ctr"><div className={step >= 3 ? "step-circle" : "step-circle step-disabled"}><h1>3</h1></div></div>
            </div>
        )
    }
}

export default StepCounter