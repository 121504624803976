import React, { Component } from 'react'
import StepCounter from './StepCounter'
import "../styles/Checkout.css";
import emailjs from '@emailjs/browser';
import { TailSpin } from 'react-loader-spinner';

class Checkout extends Component {
    state = {
        step: 1,
        telegram: "",
        fullName: "",
        streetAddress: "",
        city: "",
        state: "",
        zip: "",
        email: "",
        phone: "",
        confirmAge: false,
        isLoading: false,
    }

    componentDidMount() {
        this.setState({
            subTotal: this.props.subTotal,  
            total: this.props.total,  
            taxes: this.props.taxes,
        })
    }

    getCartHTML() {
        const parent = this.props.this;

        let cartHTML = `<tr style="background: black; color: white;">
            <th style="padding: 5px 20px">Brand</th>
            <th style="padding: 5px 20px">Product Name</th>
            <th style="padding: 5px 20px">Flavor</th>
            <th style="padding: 5px 20px">Quantity</th>
            <th style="padding: 5px 20px">Price</th>
        </tr>`;

        this.props.cart.forEach(e => {
            cartHTML += `
            <tr style="text-align: center;">
                <td style="padding: 5px 20px">${e.brand}</td>
                <td style="padding: 5px 20px">${e.productName}</td>
                <td style="padding: 5px 20px">${e.flavor}</td>
                <td style="padding: 5px 20px">${e.quantity}</td>
                <td style="padding: 5px 20px">${(parent.getSubtotal(e.price, parseInt(e.quantity), "new")).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}</td>
            </tr>
            `
        });

        return `<table style="border: 1px solid black; margin: 0px;">${cartHTML}</table>`;
    }

    sendEmail(e) {
        e.preventDefault();

        const subTotal = this.props.subTotal;
        const total = this.props.total;
        const taxes = this.props.taxes;
        const today = new Date();

        const date = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;

        const {telegram, fullName, streetAddress, city, state, zip, email, phone} = this.state;

        // emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_PUBLIC_KEY')
        //     .then((result) => {
        //         console.log(result.text);
        //     }, (error) => {
        //         console.log(error.text);
        //     });

        this.setState({
            isLoading: true,
        }, () => {
            emailjs.send("service_9ogxuwr","template_2ts60p8",{
                telegram: telegram,
                fullName: fullName,
                streetAddress: streetAddress,
                city: city,
                state: state,
                zip: zip,
                email: email,
                phone: phone,
                subTotal: subTotal,
                total: total,
                taxes: taxes,
                date: date,
                cart: this.getCartHTML(),
            }, "T2k5m4O9Fn64ApoKD").catch((err) => {
                console.log(err)
            }).then(() => {
                emailjs.send("service_9ogxuwr","template_29ikda6", {
                    telegram: telegram,
                    fullName: fullName,
                    streetAddress: streetAddress,
                    city: city,
                    state: state,
                    zip: zip,
                    email: email,
                    phone: phone,
                    subTotal: subTotal,
                    total: total,
                    taxes: taxes,
                    date: date,
                    cart: this.getCartHTML(),
                }, "T2k5m4O9Fn64ApoKD").catch((err) => {
                    console.log(err);
                }).then((res) => {
                    console.log(res);
                    this.setState({
                        step: this.state.step + 1,
                        isLoading: false,
                    })
                })
            })
        })

    }

    handleChange(e) {
        if (e.target.name === "zip") {
            if (e.target.value.length <= 5) {
                this.setState({
                    [e.target.name]: e.target.value
                })
            }
        } else if (e.target.name === "state") {
            this.setState({
                [e.target.name]: e.target.value.toUpperCase()
            })
        } else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    incrementStep(e) {
        e.preventDefault();

        this.setState({
            step: this.state.step + 1
        })
    }

    VALIDATE_Telegram() {
        const telegram = this.state.telegram;

        if (telegram.length > 0) {
            if (telegram.length < 5) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    VALIDATE_FullName() {
        const fullName = this.state.fullName;

        if (fullName.length > 0) {
            if (fullName.length < 5) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    VALIDATE_StreetAddress() {
        const streetAddress = this.state.streetAddress;

        if (streetAddress.length > 0) {
            if (streetAddress.length < 5) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    VALIDATE_City() {
        const city = this.state.city;

        if (city.length > 0) {
            if (city.length < 5) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    VALIDATE_State() {
        const state = this.state.state;

        if (state.length > 0) {
            if (state.length < 2) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    VALIDATE_Zip() {
        const zip = this.state.zip;

        if (zip.length > 0) {
            if (zip.length < 5) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    VALIDATE_Email() {
        const email = this.state.email;

        if (email.length > 0) {
            if (email.length < 5) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    VALIDATE_Phone() {
        const phone = this.state.phone;

        if (phone.length > 0) {
            if (phone.length < 5) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    validateForm() {
        const telegram = this.state.telegram;
        const fullName = this.state.fullName;
        const streetAddress = this.state.streetAddress;
        const city = this.state.city;
        const state = this.state.state;
        const zip = this.state.zip;
        const email = this.state.email;
        const phone = this.state.phone;


        const step = this.state.step;

        let isValid = false;

        if (step === 1) {
            if (telegram.length > 0 && this.VALIDATE_Telegram()) {
                if (fullName.length > 0 && this.VALIDATE_FullName()) {
                    if (streetAddress.length > 0 && this.VALIDATE_StreetAddress()) {
                        if (city.length > 0 && this.VALIDATE_City()) {
                            if (state.length > 0 && this.VALIDATE_State()) {
                                if (zip.length > 0 && this.VALIDATE_Zip()) {
                                    isValid = true;
                                }
                            }
                        }
                    }
                }
            }
        }

        if (step === 2) {
            if (email.length > 0 && this.VALIDATE_Email()) {
                if (phone.length > 0 && this.VALIDATE_Phone()) {
                    if (this.state.confirmAge === true) {
                        isValid = true;
                    }
                }
            }
        }

        return isValid;
    }

    handleCheckbox(e) {
        this.setState({
            confirmAge: e.target.checked,
        })
    }

    orderCompleteExit(e) {
        e.preventDefault();

        const parent = this.props.this;

        parent.setState({
            cart: [],
        }, () => {
            parent.exitCheckout(e);
        })
    }

    getFormByStep() {
        const parent = this.props.this;
        const step = this.state.step;

        if (this.state.isLoading === true) {
            return (<div className="checkout-form">
                <h1 className="loader-text">Submitting Order</h1>
                <div className="spinner-ctr">
                    <TailSpin
                        height="150"
                        width="150"
                        color="#51AE00"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="rings-loading"
                    />
                </div>
            </div>);
        } else {
            switch (step) {
                case 1:
                    return (<div className="checkout-form">
                        <h1>Shipping & Contact Info</h1>
                        <p>&emsp;You must be a verified member of our Telegram to place orders through our website. All orders placed without a valid corresponding Telegram username will be denied and have the funds returned to their Zelle account.</p>
                        <div className="input-left">
                            <input className={this.VALIDATE_Telegram() ? "form-input" : "form-input invalid"} onChange={e => this.handleChange(e)} type="text" name="telegram" placeholder="Telegram Username" value={this.state.telegram} />
                        </div>
                        <div className="input-left">
                            <input className={this.VALIDATE_FullName() ? "form-input" : "form-input invalid"} onChange={e => this.handleChange(e)} type="text" name="fullName" placeholder="Full Name" value={this.state.fullName} />
                        </div>
                        <div className="input-left">
                            <input className={this.VALIDATE_StreetAddress() ? "form-input" : "form-input invalid"} onChange={e => this.handleChange(e)} type="text" name="streetAddress" placeholder="Street Address" value={this.state.streetAddress} />
                        </div>
                        <div className="input-left">
                            <input className={this.VALIDATE_City() ? "form-input" : "form-input invalid"} style={{width: "40%"}} onChange={e => this.handleChange(e)} type="text" name="city" placeholder="City" value={this.state.city} />
                            <input maxLength={2} className={this.VALIDATE_State() ? "form-input" : "form-input invalid"} style={{width: "20%"}} onChange={e => this.handleChange(e)} type="text" name="state" placeholder="State" value={this.state.state} />
                            <input className={this.VALIDATE_Zip() ? "form-input" : "form-input invalid"} style={{width: "15%"}} onChange={e => this.handleChange(e)} type="number" name="zip" placeholder="ZIP" value={this.state.zip} />
                        </div>
                        <div className="input-left">
                        </div>
                        <div className="input-left">
                        </div>
                        <button disabled={!this.validateForm()} onClick={e => this.incrementStep(e)} className="checkout-btn">Continue to Payment</button>
                        <button onClick={e => parent.exitCheckout(e)} className="checkout-exit-btn">Go Back to Home Page</button>
                    </div>)
                case 2:
                    return (<div className="checkout-form">
                        <h1>Payment Information</h1>
                        <p>&emsp;We use Zelle to handle payments on our website. Please enter your Zelle information below so that we may verify your purchase.</p>
                        <form onSubmit={e => this.sendEmail(e)}>
                            <input className={this.VALIDATE_Email() ? "form-input" : "form-input invalid"} onChange={e => this.handleChange(e)} type="text" name="email" placeholder="Email Address" value={this.state.email} />
                            <input className={this.VALIDATE_Phone() ? "form-input" : "form-input invalid"} onChange={e => this.handleChange(e)} type="text" name="phone" placeholder="Phone" value={this.state.phone} />
                            <div className="checkbox-ctr">
                                <div className="checkbox">
                                    <input type="checkbox" id="confirmAge" name="confirmAge" onChange={e => this.handleCheckbox(e)} />
                                </div>
                                <p>By checking this box and placing this order, I confirm that I am of legal age in the United States (21 and older) to purchase these products. This is my own account and payment information, and I have no intent to distribute any product(s) to an individual who is not of legal age to purchase it themselves.</p>
                            </div>
                            <button disabled={!this.validateForm()} onClick={e => this.sendEmail(e)} className="checkout-btn">Place Order</button>
                            <button onClick={e => parent.exitCheckout(e)} className="checkout-exit-btn">Go Back to Home Page</button>
                        </form>
                    </div>)
                case 3:
                    return (<div className="checkout-form">
                        <h1>Order Placed!</h1>
                        <p>&emsp;Your order has been submitted and will be reviewed once payment has been confirmed.</p>
                        <p>&emsp;Your total is <b>{this.state.total}</b>. Please send the full amount of your payment through <b>Zelle</b>.</p>
                        <p>&emsp;Please make sure to include your Telegram Username in your Zelle payment message.</p>
                        <div className="checkout-message">
                            <p>Use Zelle to send {this.state.total} to</p>
                            <h1>payment@sticksofnics.com</h1>
                            <p style={{fontSize: "14px"}}>Thank you for shopping with us!</p>
                        </div>
                        <button onClick={e => this.orderCompleteExit(e)} className="checkout-exit-btn">Go Back to Home Page</button>
                    </div>)
                default:
                    return (<div>
                        How did you find me?
                    </div>)
            }
        }

    }

    render() {
        return (
            <div className="checkout">
                <h2>Checkout</h2>
                <StepCounter this={this} isLoading={this.state.isLoading} step={this.state.step}/>
                {this.getFormByStep()}
            </div>
        )
    }
}

export default Checkout