import React, { Component } from 'react';

// AIRBAR NEX 6500 Images
import AIRBAR_NEX_65000_Default from "../images/AIRBAR NEX 6500/AIRBAR NEX 6500.jpg";
import AIRBAR_NEX_65000_MiamiMint from "../images/AIRBAR NEX 6500/AIRBAR NEX 6500 Miami Mint.png";
import AIRBAR_NEX_65000_CoolMint from "../images/AIRBAR NEX 6500/AIRBAR NEX 6500 Cool Mint.png";
import AIRBAR_NEX_65000_GrapeIce from "../images/AIRBAR NEX 6500/AIRBAR NEX 6500 Grape Ice.png";
import AIRBAR_NEX_65000_BlueberryIce from "../images/AIRBAR NEX 6500/AIRBAR NEX 6500 Blueberry Ice.png";
import AIRBAR_NEX_65000_BlueberryGrapeMint from "../images/AIRBAR NEX 6500/AIRBAR NEX 6500 Blueberry Grape Mint.png";
import AIRBAR_NEX_65000_LoveStory from "../images/AIRBAR NEX 6500/AIRBAR NEX 6500 Love Story.png";

// LUFFBAR Bubble 6000 Images
import LUFFBAR_Bubble_6000_Default from "../images/LUFFBAR Bubble 6000/luffbar-bubble-6000.jpg";
import LUFFBAR_Bubble_6000_BlueberryIce from "../images/LUFFBAR Bubble 6000/luffbar-bubble-6000 Blueberry Ice.png";
import LUFFBAR_Bubble_6000_BlueberryRaspberry from "../images/LUFFBAR Bubble 6000/luffbar-bubble-6000 Blueberry Raspberry.png";
import LUFFBAR_Bubble_6000_CoolMint from "../images/LUFFBAR Bubble 6000/luffbar-bubble-6000 Cool Mint.png";
import LUFFBAR_Bubble_6000_EnergyDrink from "../images/LUFFBAR Bubble 6000/luffbar-bubble-6000 Energy Drink.png";
import LUFFBAR_Bubble_6000_JuicyWatermelon from "../images/LUFFBAR Bubble 6000/luffbar-bubble-6000 Juicy Watermelon.png";
import LUFFBAR_Bubble_6000_RainbowCandy from "../images/LUFFBAR Bubble 6000/luffbar-bubble-6000 Rainbow Candy.png";
import LUFFBAR_Bubble_6000_SourApple from "../images/LUFFBAR Bubble 6000/luffbar-bubble-6000 Sour Apple.png";
import LUFFBAR_Bubble_6000_StrawberryWatermelon from "../images/LUFFBAR Bubble 6000/luffbar-bubble-6000 Strawberry Watermelon.png";
import LUFFBAR_Bubble_6000_WatermelonIce from "../images/LUFFBAR Bubble 6000/luffbar-bubble-6000 Watermelon Ice.png";

// PACKSPOD 5000 Images
import PACKSPOD_5000_Default from "../images/PACKSPOD 5000/packspod-5000.jpg";
import PACKSPOD_5000_GelatoFreeze from "../images/PACKSPOD 5000/packspod-5000 Gelato Freeze.png";
import PACKSPOD_5000_MiamiHaze from "../images/PACKSPOD 5000/packspod-5000 Miami Haze.png";
import PACKSPOD_5000_OrangeCreamsicle from "../images/PACKSPOD 5000/packspod-5000 Orange Creamsicle.png";
import PACKSPOD_5000_SourGushers from "../images/PACKSPOD 5000/packspod-5000 Sour Gushers.png";
import PACKSPOD_5000_UnicornSherbert from "../images/PACKSPOD 5000/packspod-5000 Unicorn Sherbert.png";
import PACKSPOD_5000_BlueSlurpie from "../images/PACKSPOD 5000/packspod-5000 Blue Slurpie.png";

// RAZ CA6000 Images
import RAZ_CA6000_Default from "../images/RAZ CA6000/raz ca6000.png";
import RAZ_CA6000_CrushedBerries from "../images/RAZ CA6000/raz ca6000 Crushed Berries.png";
import RAZ_CA6000_WatermelonIce from "../images/RAZ CA6000/raz ca6000 Watermelon Ice.png";
import RAZ_CA6000_AlaskanMint from "../images/RAZ CA6000/raz ca6000 Alaskan Mint.png";
import RAZ_CA6000_GeorgiaPeach from "../images/RAZ CA6000/raz ca6000 Georgia Peach.png";
import RAZ_CA6000_Clear from "../images/RAZ CA6000/raz ca6000 Clear.png";
import RAZ_CA6000_Tobacco from "../images/RAZ CA6000/raz ca6000 Tobacco.png";

// Pod 5500 Images
import Pod_5500_Default from "../images/Pod 5500/Pod 5500 Default.png";
import Pod_5500_AloeGrape from "../images/Pod 5500/Pod 5500 Aloe Grape.png";
import Pod_5500_ArcticBlueRazzWatermelon from "../images/Pod 5500/Pod 5500 Arctic Blue Razz Watermelon.png";
import Pod_5500_BerryLushApple from "../images/Pod 5500/Pod 5500 Berry Lush Apple.png";
import Pod_5500_BlueRazzChew from "../images/Pod 5500/Pod 5500 Blue Razz Chew.png";
import Pod_5500_ChilledBlueRazz from "../images/Pod 5500/Pod 5500 Chilled Blue Razz.png";
import Pod_5500_GlacierFujiApple from "../images/Pod 5500/Pod 5500 Glacier Fuji Apple.png";
import Pod_5500_Hawaiian from "../images/Pod 5500/Pod 5500 Hawaiian.png";
import Pod_5500_JewelTobacco from "../images/Pod 5500/Pod 5500 Jewel Tobacco.png";
import Pod_5500_MightyMintSapphire from "../images/Pod 5500/Pod 5500 Mighty Mint Sapphire.png";
import Pod_5500_PodEnergy from "../images/Pod 5500/Pod 5500 Pod Energy.png";
import Pod_5500_StrawberrySnowCone from "../images/Pod 5500/Pod 5500 Strawberry Snow Cone.png";
import Pod_5500_WatermelonSoftChew from "../images/Pod 5500/Pod 5500 Watermelon Soft Chew.png";

// Slaps 4500 Images
import Slaps_4500_Default from "../images/Slaps 4500/Slaps 4500 Default.png";
import Slaps_4500_LushIce from "../images/Slaps 4500/Slaps 4500 Lush Ice.png";

// Vibez R 2500 Images
import Vibez_R2500_Default from "../images/Vibez R 2500/vibez r 2500 Default.png";
import Vibez_R2500_AloeDragonFruit from "../images/Vibez R 2500/vibez r 2500 Aloe Dragon Fruit.png";
import Vibez_R2500_FrostedBerryMix from "../images/Vibez R 2500/vibez r 2500 Frosted Berry Mix.png";
import Vibez_R2500_FrostedMint from "../images/Vibez R 2500/vibez r 2500 Frosted Mint.png";
import Vibez_R2500_FrostedPeachRaspberry from "../images/Vibez R 2500/vibez r 2500 Frosted Peach Raspberry.png";
import Vibez_R2500_FrostedStrawberryBanana from "../images/Vibez R 2500/vibez r 2500 Frosted Strawberry Banana.png";
import Vibez_R2500_StrawberryBobaTea from "../images/Vibez R 2500/vibez r 2500 Strawberry Boba Tea.png";

// VIHO 10000 Images
import VIHO_10000_Default from "../images/VIHO 10000/VIHO 10000 Default.png";
import VIHO_10000_BlueRazzIcy from "../images/VIHO 10000/VIHO 10000 Blue Razz Icy.png";
import VIHO_10000_LAMint from "../images/VIHO 10000/VIHO 10000 LA Mint.png";
import VIHO_10000_MangoIcy from "../images/VIHO 10000/VIHO 10000 Mango Icy.png";
import VIHO_10000_PassionFruitIcy from "../images/VIHO 10000/VIHO 10000 Passion Fruit Icy.png";
import VIHO_10000_WatermelonIcy from "../images/VIHO 10000/VIHO 10000 Watermelon Icy.png";
import VIHO_10000_Clear from "../images/VIHO 10000/VIHO 10000 Clear.png";
import VIHO_10000_Tobacco from "../images/VIHO 10000/VIHO 10000 Tobacco.png";

// MONQ Essential Oil Images
import MONQ_focus from "../images/MONQ/focus.png";
import MONQ_ocean from "../images/MONQ/ocean.png";
import MONQ_relieve from "../images/MONQ/relieve.png";
import MONQ_sexy from "../images/MONQ/sexy.png";
import MONQ_zen from "../images/MONQ/zen.png";
import MONQ_forest from "../images/MONQ/forest.png";
import MONQ_default from "../images/MONQ/default.png";

class Image extends Component {
  getImage() {
    let newClass;
    let source;

    if (this.props.type === "card") {
        newClass = "image-ctr card-img";
    }

    if (this.props.type === "view") {
        newClass = "image-ctr view-img";
    }
    
    if (this.props.type === "cart") {
        newClass = "image-ctr cart-img";
    }

    if (this.props.brand === "AIRBAR") {
      switch(this.props.flavor) {
        case "Miami Mint":
          source = AIRBAR_NEX_65000_MiamiMint;
          break;
        case "Cool Mint":
          source = AIRBAR_NEX_65000_CoolMint;
          break;
        case "Grape Ice":
          source = AIRBAR_NEX_65000_GrapeIce;
          break;
        case "Blueberry Grape Mint":
          source = AIRBAR_NEX_65000_BlueberryGrapeMint;
          break;
        case "Blueberry Ice":
          source = AIRBAR_NEX_65000_BlueberryIce;
          break;
        case "Love Story":
          source = AIRBAR_NEX_65000_LoveStory;
          break;
        default:
          source = AIRBAR_NEX_65000_Default;
      }
    }
    if (this.props.brand === "LUFFBAR") {
      switch(this.props.flavor) {
        case "Blueberry Ice":
          source = LUFFBAR_Bubble_6000_BlueberryIce;
          break;
        case "Blueberry Raspberry":
          source = LUFFBAR_Bubble_6000_BlueberryRaspberry;
          break;
        case "Cool Mint":
          source = LUFFBAR_Bubble_6000_CoolMint;
          break;
        case "Energy Drink":
          source = LUFFBAR_Bubble_6000_EnergyDrink;
          break;
        case "Juicy Watermelon":
          source = LUFFBAR_Bubble_6000_JuicyWatermelon;
          break;
        case "Rainbow Candy":
          source = LUFFBAR_Bubble_6000_RainbowCandy;
          break;
        case "Sour Apple":
          source = LUFFBAR_Bubble_6000_SourApple;
          break;
        case "Strawberry Watermelon":
          source = LUFFBAR_Bubble_6000_StrawberryWatermelon;
          break;
        case "Watermelon Ice":
          source = LUFFBAR_Bubble_6000_WatermelonIce;
          break;
        default:
          source = LUFFBAR_Bubble_6000_Default;
      }
    }
    if (this.props.brand === "PACKSPOD") {
      switch(this.props.flavor) {
        case "Gelato Freeze":
          source = PACKSPOD_5000_GelatoFreeze;
          break;
        case "Miami Haze":
          source = PACKSPOD_5000_MiamiHaze;
          break;
        case "Orange Creamsicle":
          source = PACKSPOD_5000_OrangeCreamsicle;
          break;
        case "Sour Gushers":
          source = PACKSPOD_5000_SourGushers;
          break;
        case "Unicorn Sherbert":
          source = PACKSPOD_5000_UnicornSherbert;
          break;
        case "Blue Slurpie":
          source = PACKSPOD_5000_BlueSlurpie;
          break;
        default:
          source = PACKSPOD_5000_Default;
      }
    }
    if (this.props.brand === "RAZ") {
      switch(this.props.flavor) {
        case "Crushed Berries":
          source = RAZ_CA6000_CrushedBerries;
          break;
        case "Watermelon Ice":
          source = RAZ_CA6000_WatermelonIce;
          break;
        case "Alaskan Mint":
          source = RAZ_CA6000_AlaskanMint;
          break;
        case "Georgia Peach":
          source = RAZ_CA6000_GeorgiaPeach;
          break;
        case "Clear":
          source = RAZ_CA6000_Clear;
          break;
        case "Tobacco":
          source = RAZ_CA6000_Tobacco;
          break;
        default:
          source = RAZ_CA6000_Default;
      }
    }
    if (this.props.brand === "Pod") {
      switch(this.props.flavor) {
        case "Aloe Grape":
          source = Pod_5500_AloeGrape;
          break;
        case "Arctic Blue Razz Watermelon":
          source = Pod_5500_ArcticBlueRazzWatermelon;
          break;
        case "Berry Lush Apple":
          source = Pod_5500_BerryLushApple;
          break;
        case "Blue Razz Chew":
          source = Pod_5500_BlueRazzChew;
          break;
        case "Chilled Blue Razz":
          source = Pod_5500_ChilledBlueRazz;
          break;
        case "Glacier Fuji Apple":
          source = Pod_5500_GlacierFujiApple;
          break;
        case "Hawaiian":
          source = Pod_5500_Hawaiian;
          break;
        case "Jewel Tobacco":
          source = Pod_5500_JewelTobacco;
          break;
        case "Mighty Mint Sapphire":
          source = Pod_5500_MightyMintSapphire;
          break;
        case "Pod Energy":
          source = Pod_5500_PodEnergy;
          break;
        case "Strawberry Snow Cone":
          source = Pod_5500_StrawberrySnowCone;
          break;
        case "Watermelon Soft Chew":
          source = Pod_5500_WatermelonSoftChew;
          break;
        default:
          source = Pod_5500_Default;
      }
    }
    if (this.props.brand === "Slaps") {
      switch(this.props.flavor) {
        case "Lush Ice":
          source = Slaps_4500_LushIce;
          break;
        default:
          source = Slaps_4500_Default;
      }
    }
    if (this.props.brand === "Vibez") {
      switch(this.props.flavor) {
        case "Aloe Dragon Fruit":
          source = Vibez_R2500_AloeDragonFruit;
          break;
        case "Frosted Berry Mix":
          source = Vibez_R2500_FrostedBerryMix;
          break;
        case "Frosted Mint":
          source = Vibez_R2500_FrostedMint;
          break;
        case "Frosted Peach Raspberry":
          source = Vibez_R2500_FrostedPeachRaspberry;
          break;
        case "Frosted Strawberry Banana":
          source = Vibez_R2500_FrostedStrawberryBanana;
          break;
        case "Strawberry Boba Tea":
          source = Vibez_R2500_StrawberryBobaTea;
          break;
        default:
          source = Vibez_R2500_Default;
      }
    }
    if (this.props.brand === "VIHO") {
      switch(this.props.flavor) {
        case "Blue Razz Icy":
          source = VIHO_10000_BlueRazzIcy;
          break;
        case "LA Mint":
          source = VIHO_10000_LAMint;
          break;
        case "Mango Icy":
          source = VIHO_10000_MangoIcy;
          break;
        case "Passion Fruit Icy":
          source = VIHO_10000_PassionFruitIcy;
          break;
        case "Watermelon Icy":
          source = VIHO_10000_WatermelonIcy;
          break;
        case "Clear":
          source = VIHO_10000_Clear;
          break;
        case "Tobacco":
          source = VIHO_10000_Tobacco;
          break;
        default:
          source = VIHO_10000_Default;
      }
    }
    if (this.props.brand === "MONQ") {
      switch(this.props.flavor) {
        case "Focus":
          source = MONQ_focus;
          break;
        case "Ocean":
          source = MONQ_ocean;
          break;
        case "Relieve":
          source = MONQ_relieve;
          break;
        case "Sexy":
          source = MONQ_sexy;
          break;
        case "Zen":
          source = MONQ_zen;
          break;
        case "Forest":
          source = MONQ_forest;
          break;
        default:
          source = MONQ_default;
      }
    }

    return (<div className={newClass}>
            <img src={source} alt={`${this.props.brand} ${this.props.productName}`} />
        </div>);
  } 

  render() {
    return (this.getImage())
  }
}

export default Image