import React, { Component } from 'react'
import Image from './Image';

class Cart extends Component {
    getCart() {
        const parent = this.props.this;
        const cart = parent.state.cart;
        let matchingProduct;
        let flavors;
        const qtyOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    
        return <div className="cart-items">
          {cart.map((item, i) => {
            matchingProduct = parent.getMatchingProduct(item);
            flavors = matchingProduct.flavors.slice(1);
            return (<div className="cart-item" key={`cart-item-${i}`}>
              <Image type="cart" brand={item.brand} name={item.productName} flavor={item.flavor}/>
              <div className="cart-info-ctr">  
                <p><b>{item.brand}</b> {item.productName}</p>
                {/* <div className="view-info">
                  <p>Flavors
                    <select className="flavor-select" defaultValue={item.flavor} onChange={e => parent.handleCartFlavorChange(e, i)}>
                      {flavors.map((flavor, i) => (
                        <option key={i} value={flavor.name}>
                          {flavor.name}
                        </option>
                      ))}
                    </select>
                    Qty
                    <select className="qty-select" defaultValue={item.quantity} onChange={e => parent.handleCartQtyChange(e, i)}>
                      {qtyOptions.map((val, i) => (
                        <option key={i} value={val}>
                          {val}
                        </option>
                      ))}
                    </select>                
                  </p>
                </div> */}
                <div className="view-info-small view-info">
                  <p>Flavors
                    <select className="cart-flavor-select" defaultValue={item.flavor} onChange={e => parent.handleCartFlavorChange(e, i)}>
                      {flavors.map((flavor, i) => (
                        <option key={i} value={flavor.name}>
                          {flavor.name}
                        </option>
                      ))}
                    </select>
                  </p>
                  <p>
                    Qty
                    <select className="cart-qty-select" defaultValue={item.quantity} onChange={e => parent.handleCartQtyChange(e, i)}>
                      {qtyOptions.map((val, i) => (
                        <option key={i} value={val}>
                          {val}
                        </option>
                      ))}
                    </select>                
                  </p>
                </div>
              </div>
              <div className="cart-price-ctr">
                <p className="cut-price" style={parent.getCartCount() < 2 ? {display: "none"} : {display: "block"}}><b>{(parent.getSubtotal(item.price, parseInt(item.quantity), "old")).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}</b></p>
                <p className="final-price"><b>{(parent.getSubtotal(item.price, parseInt(item.quantity), "new")).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}</b></p>
              </div>
              <div className="msg-ctr">
                  <p>{item.quantity} {item.brand} {item.productName}&#40;s&#41; at {(parent.getSubtotal(item.price, parseInt(item.quantity), "per")).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })} each.</p>
                </div>
            </div>)
          })}
          {parent.getSavings() === 0 ?
            <div className="cart-msg">
                <p>Buy 2 products to get 25% off!</p>
            </div>
          :
            <div className="cart-msg">
                <p>You've saved ${parent.getSavings()} on this order!</p>
                {parent.getCartCount() >= 10 ?
                  <p>You're also getting a free vape!</p> :
                  <p>Buy 10 products to get a free vape!</p>
                }
            </div>
          }
        </div>
    }

    render() {
        const parent = this.props.this;
        
        return (
            <div className="product-list product-list-enter">
                <h2>Shopping Cart</h2>
                {this.getCart()}
                <div className="cart-total-ctr">
                    <div className="cart-subtotal">
                    <p style={{textAlign: "left", marginLeft: "20%"}}>Subtotal:</p><p style={{textAlign: "right", marginRight: "20%"}}>{parent.getPrice("subtotal")}</p>
                    </div>
                    <div className="cart-subtotal">
                    <p style={{textAlign: "left", marginLeft: "20%"}}>Tax & Shipping:</p><p style={{textAlign: "right", marginRight: "20%"}}>{parent.getPrice("taxes")}</p>
                    </div>
                    <div className="cart-total">
                    <p style={{textAlign: "left", marginLeft: "20%"}}>Total:</p><p style={{textAlign: "right", marginRight: "20%"}}>{parent.getPrice("total")}</p>
                    <button className="checkout-btn" onClick={e => parent.viewCheckout(e)}>Proceed to Checkout</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Cart