import React, { Component } from 'react';
import Card from './Card';

class Products extends Component {
  render() {
    const parent = this.props.this;

    return (
        <div className="product-list product-list-enter">
            <h2>All Products</h2>
            <div className="cards-ctr">
                {parent.state.displayProducts.map(product => {
                    return <Card product={product} parent={parent}/>
                })}
                <p style={{opacity: "0.5", margin: "50px auto 100px"}}>More options on the way!</p>
            </div>
        </div>
    )
  }
}

export default Products