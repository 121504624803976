import React, { Component } from 'react';
import "../styles/Privacy.css";

class Privacy extends Component {
  render() {
    return (
      <div className="privacy-text">
        <p>Privacy Policy</p>
        <p>Effective Date: 7/16/2023</p>
        <p>Thank you for visiting our website. We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard your information when you use our website to purchase nicotine vapes. Please take a moment to read this policy to understand how we handle your data.</p>
        <p>1. Information We Collect</p>
        <p>1.1 Personal Information: When you visit our website and make a purchase, we may collect certain personally identifiable information from you, such as your name, address, email address, and telephone number. This information is necessary to process your order and fulfill your purchase.</p>
        <p>1.2 Age Verification: To comply with applicable laws and regulations, we require customers to be at least 21 years of age to purchase nicotine vapes. During the checkout process, we may collect additional information to verify your age, such as your date of birth or government-issued identification.</p>
        <p>1.3 Usage Data: We may collect non-personal information about your visit to our website, including your IP address, browser type, operating system, referring website, pages viewed, and the duration of your visit. This information is collected automatically through cookies and similar technologies to improve our website's functionality and user experience.</p>
        <p>2. Use of Information</p>
        <p>2.1 Order Fulfillment: We use the personal information provided during the purchase process to process your order, ship the products, and communicate with you regarding your purchase, such as order confirmation, shipment notifications, and customer support.</p>
        <p>2.2 Age Verification: The age verification information you provide is used solely to confirm your eligibility to purchase nicotine vapes. We do not store or retain this information beyond the scope of age verification.</p>
        <p>2.3 Internal Analytics: We may analyze usage data, such as website traffic patterns and customer preferences, to improve our website's performance, enhance user experience, and optimize our product offerings. This analysis is performed at an aggregated level and does not identify individual users.</p>
        <p>3. Information Sharing</p>
        <p>3.1 Third-Party Service Providers: We may engage trusted third-party service providers to assist us in operating our website, fulfilling orders, processing payments, or performing other business functions. These service providers have access to your personal information only to the extent necessary to perform their respective tasks and are obligated to handle it in accordance with this Privacy Policy.</p>
        <p>3.2 Legal Compliance: We may disclose your personal information if required by law, regulation, or legal process, or to protect our rights, safety, or property, or the rights, safety, or property of others.</p>
        <p>4. Data Security</p>
        <p>We implement reasonable security measures to protect your personal information from unauthorized access, use, or disclosure. However, please be aware that no data transmission over the internet or electronic storage method is 100% secure, and we cannot guarantee absolute security.</p>
        <p>5. Your Rights and Choices</p>
        <p>5.1 Updating Information: You have the right to access, update, or correct your personal information held by us. If you wish to make changes, please contact us using the information provided in the "Contact Us" section below.</p>
        <p>5.2 Marketing Communications: If you have opted in to receive marketing communications from us, you may opt-out at any time by following the instructions provided in the communication or by contacting us directly.</p>
        <p>6. Children's Privacy</p>
        <p>Our website is not directed to individuals under the age of 21, and we do not knowingly collect or maintain personal information from anyone under 21 years of age. If we become aware that we have inadvertently collected personal information from an individual under 21, we will take steps to delete the information as soon as possible.</p>
        <p>7. Updates to this Privacy Policy</p>
        <p>We reserve the right to update this Privacy Policy periodically to reflect changes in our information practices. Any changes will be posted on this page, and the revised Privacy Policy will take effect immediately upon posting. We encourage you to review this Privacy Policy regularly to stay informed about how we collect, use, and protect your personal information.</p>
        <p>8. Contact Us</p>
        <p>If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at:</p>
        <p>orders@sticksofnics.com</p>
        <p>By using our website, you acknowledge that you have read and understood this Privacy Policy and agree to the collection, use, and disclosure of your personal information as described herein.</p>
      </div>
    )
  }
}

export default Privacy