import React, { Component } from 'react';
import "./App.css";
import Sticks from "./images/sticks logo.png";
import EmptyCart from "./images/cart-empty.png";
import data from "./data.json";
import Products from './components/Products';
import X from "./images/x.png";
import XBlack from "./images/x black.png";
import ViewProduct from './components/ViewProduct';
import Cart from './components/Cart';
import Checkout from './components/Checkout';
import CartAlert from './components/CartAlert';
import ToS from './components/ToS';
import Privacy from './components/Privacy';
// import { ref, onValue } from "firebase/database";
import TGLogo from "./images/tg-logo.png";
import IGLogo from "./images/ig-logo.png";
import SCLogo from "./images/snap-logo.png";

class App extends Component {
    state = {
      isAgeVerified: false,
      quantity: [],
      data: {},
      cart: [],
      displayProducts: [],
      showProducts: false,
      isViewing: false,
      viewProduct: {},
      showCart: false,
      viewCheckout: false,
      subTotal: 0,
      taxes: 0,
      total: 0,
      message: "",
      cartAlert: "",
      showPrivacyPolicy: false,
    }

    componentDidMount() {
      // const db = this.props.database;

      // const productRef = ref(db, 'products/');
      // onValue(productRef, (snapshot) => {
      //   const data = snapshot.val();

      //   this.setState({
      //     data: data
      //   }, () => {
      //     const products = data;
      
      //     products.forEach(p => {
      //       this.setState({
      //         [`${p.brand} ${p.productName}`]: "0",
      //         [`${p.brand} ${p.productName} flavor`]: "Select a Flavor",
      //       })
      //     })
      //   })
        
      // });
      this.setState({
        data: data.products
      }, () => {
        const products = data.products;
    
        products.forEach(p => {
          this.setState({
            [`${p.brand} ${p.productName}`]: "0",
            [`${p.brand} ${p.productName} flavor`]: "Select a Flavor",
          })
        })
      })
    }

    getSavings() {
      const cart = this.state.cart;

      let originalPrice = 0;
      let newPrice = 0;

      cart.forEach(item => {
        originalPrice += this.getSubtotal(item.price, item.quantity, "old");
        newPrice += this.getSubtotal(item.price, item.quantity, "new");
      })

      return originalPrice - newPrice;
    }

    getCartCount() {
      let quantity = 0;
  
      for (let x = 0; x < this.state.cart.length; x++) {
        quantity += parseInt(this.state.cart[x].quantity);
      }
  
      return quantity;
    }

    getProductsWindow(e, selector) {
      e.preventDefault();
      const data = this.state.data;

      let products = [];
  
      if (selector === "nicotine") {
        data.forEach(e => {
          if (e.nicPercent > 0) {
            products.push(e);
          }
        })
      }

      if (selector === "essential") {
        data.forEach(e => {
          if (e.nicPercent === 0) {
            products.push(e);
          }
        })
      }

      console.log(products);
  
      this.setState({
        displayProducts: products,
        showProducts: true,
      })
    }

    closeProductsWindow(e) {
      e.preventDefault();
  
      const products = this.state.data;
  
      products.forEach(p => {
        this.setState({
          [`${p.brand} ${p.productName}`]: "0",
          [`${p.brand} ${p.productName} flavor`]: p.flavors[0].name,
        })
      })
  
      this.setState({
        showProducts: false,
      })
    }

    addToCart(e, p) {
      e.preventDefault();
  
      let cart = this.state.cart;
      const quantity =  this.state[`${p.brand} ${p.productName}`];
  
      const message = `${quantity} ${this.state[`${p.brand} ${p.productName} flavor`]} ${p.brand} ${p.productName}(s) Added to Cart`;
  
      cart.push({
        brand: p.brand,
        productName: p.productName,
        flavor: this.state[`${p.brand} ${p.productName} flavor`],
        price: 20,
        quantity: quantity,
      })

      this.setState({
        cartAlert: "",
      }, () => {
        this.setState({
          cart: cart,
          [`${p.brand} ${p.productName}`]: "0",
          [`${p.brand} ${p.productName} flavor`]: "Select a Flavor",
          isViewing: false,
          cartAlert: <CartAlert message={message} />
        });
      })
    }

    viewProduct(e, p) {
      e.preventDefault();
  
      this.setState({
        isViewing: true,
        viewProduct: p,
      })
    }

    exitViewProduct(e) {
      e.preventDefault();
  
      this.setState({
        isViewing: false,
      })
    }

    handleQtySelectorChange(e, p) {
      this.setState({
        [`${p.brand} ${p.productName}`]: e.target.value,
      });
    }
  
    handleSelectorChange(e, p) {
      this.setState({
        [`${p.brand} ${p.productName} flavor`]: e.target.value
      });
    }

    showCart(e) {
      e.preventDefault();
  
      this.setState({
        showProducts: false,
        isViewing: false,
        showCart: true,
      })
    }
  
    exitCart(e) {
      e.preventDefault();
  
      // const products = this.state.data.products;
  
      // products.forEach(p => {
      //   this.setState({
      //     [`${p.brand} ${p.productName}`]: "0",
      //     [`${p.brand} ${p.productName} flavor`]: p.flavors[0].name,
      //   })
      // })
  
      this.setState({
        showCart: false,
      })
    }

    togglePrivacy(e) {
      e.preventDefault();

      this.setState({
        showPrivacyPolicy: !this.state.showPrivacyPolicy,
      })
    }

    getMatchingProduct(item) {
      const products = this.state.data;
  
      let curProduct;
      
      for (let x = 0; x < products.length; x++) {
        if (products[x].brand === item.brand) {
          if (products[x].productName === item.productName) {
            curProduct = products[x];
            x = products.length;
          }
        }
      }
  
      return curProduct;
    }

    handleCartFlavorChange(e, i) {
      e.preventDefault();
  
      let curCart = this.state.cart;
      let editItem = this.state.cart[i];
  
      editItem.flavor = e.target.value;
  
      curCart[i] = editItem;
  
      this.setState({
        cart: curCart
      })
    }
  
    handleCartQtyChange(e, i) {
      e.preventDefault();
  
      let curCart = this.state.cart;
      let editItem = this.state.cart[i];
  
      editItem.quantity = e.target.value;
  
      curCart[i] = editItem;
  
      this.setState({
        cart: curCart
      })
    }

    getSubtotal(p, q, sel) {
      const cartCount = this.getCartCount();
  
      if (sel === "old") {
        return p * q;
      } else {
        if (cartCount >= 100) {
          p *= .60;
        } else {
          if (cartCount >= 2) {
            p *= .75;
          }
        }
  
        if (sel === "per") {
          return p;
        } else {
          return p * q;
        }
      }
    }
  
    getPrice(sel) {
      const cart = this.state.cart;
      let subTotal = 0;
  
      for (let x = 0; x < cart.length; x++) {
        subTotal += this.getSubtotal(cart[x].price, cart[x].quantity, "new");
      }
  
      const taxes = subTotal * 0.08625;
  
      const total = taxes + subTotal;
  
      if (sel === "subtotal") {
        return subTotal.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      }
  
      if (sel === "taxes") {
        return taxes.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      }
  
      if (sel === "total") {
        return total.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      }
    }
  
    viewCheckout(e) {
      e.preventDefault();
  
      const cart = this.state.cart;
      let subTotal = 0;
  
      for (let x = 0; x < cart.length; x++) {
        subTotal += this.getSubtotal(cart[x].price, cart[x].quantity, "new");
      }
  
      const taxes = subTotal * 0.08625;
  
      const total = taxes + subTotal;
  
      this.setState({
        subTotal: subTotal.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
        taxes: taxes.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
        total: total.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
        viewCheckout: true,
      })
    }

    exitCheckout(e) {
      e.preventDefault();

      this.setState({
        viewCheckout: false,
        showCart: false,
      })
    }

    render() {
      const cartLength = this.state.cart.length;
      const showProducts = this.state.showProducts;
      const isViewing = this.state.isViewing;
      const viewProduct = this.state.viewProduct;
      const showCart = this.state.showCart;

      return (
        <div className="app-ctr">
          <div className="logo-ctr">
            <img src={Sticks} alt="Sticks Logo" />
          </div>
          {this.state.showPrivacyPolicy ? 
          <div className="privacy-policy-ctr">
            <div className="privacy-cancel-ctr" onClick={e => this.togglePrivacy(e)}>
              <img src={XBlack} alt="Cancel" />
            </div>
            <Privacy />
          </div> : <div className="privacy-policy-btn">
            <p onClick={e => this.togglePrivacy(e)}>View Our Privacy Policy</p>
          </div>
          }
          {this.state.isAgeVerified ? <div>
            {this.state.cartAlert}
          {this.state.viewCheckout === true ? 
            <div className="checkout-ctr">
              <Checkout this={this} cart={this.state.cart} subTotal={this.state.subTotal} total={this.state.total} taxes={this.state.taxes}/>
            </div> : 
          <div></div>}
          <div className="buttons-ctr">
            <div className="btn-ctr" onClick={e => this.getProductsWindow(e, "nicotine")}>
              <button className="menu-btn">NICOTINE VAPES</button>
            </div>
            <div className="btn-ctr" onClick={e => this.getProductsWindow(e, "essential")}>
              <button className="menu-btn">ESSENTIAL OILS</button>
            </div>
            <a href="https://tools.usps.com/go/TrackConfirmAction_input" target='_blank' rel="noreferrer">
              <div className="btn-ctr">
                <button className="menu-btn">TRACK YOUR ORDER</button>
              </div>
            </a>
            {/* <div className="btn-ctr">
              <button disabled={cartLength <= 0} onClick={e => this.showCart(e)} className="menu-btn">CHECKOUT ({this.getCartCount()})</button>
            </div> */}
          </div>
          <div className="socials-ctr">
            <a href="https://t.me/+TmhwkR_-zZ02ODdh" rel="noreferrer" target="_blank">
              <div className="social-icon">
                <img src={TGLogo} alt="Telegram" />
              </div>
            </a>
            <a href="https://t.snapchat.com/3Sk3nd5T" rel="noreferrer" target="_blank">
              <div className="social-icon">
                <img src={SCLogo} alt="Snapchat" />
              </div>
            </a>
            <a href="https://instagram.com/sticksmain?igshid=OGQ5ZDc2ODk2ZA==" rel="noreferrer" target="_blank">
              <div className="social-icon">
                <img src={IGLogo} alt="Instagram" />
              </div>
            </a>
          </div>
          <div onClick={e => this.showCart(e)} className={cartLength <= 0 || showCart ? "cart-ctr cart-disabled" : "cart-ctr"}>
            <img src={EmptyCart} alt="Cart"/>
            <h3>&#40;{this.getCartCount()}&#41;</h3>
          </div>
          {showProducts ? <div className="products-ctr">
            <div className="bg-overlay"></div>
            <div className="cancel-ctr" onClick={isViewing ? e => this.exitViewProduct(e) : e => this.closeProductsWindow(e)}>
              <img src={X} alt="Cancel" />
            </div>
            {isViewing ? 
            <ViewProduct this={this} viewProduct={viewProduct} />
            : 
            <Products this={this} />
            }
          </div> : showCart ? <div className="products-ctr">
            <Cart this={this} />
            <div className="bg-overlay"></div>
            {this.state.viewCheckout ? <div></div> : 
              <div className="cancel-ctr" onClick={e => this.exitCart(e)}>
                <img src={X} alt="Cancel" />
              </div>
            }
          </div> : 
          <div></div>}
          </div> : <div className="age-check-ctr">
            <div className="bg-overlay"></div>
            <ToS this={this}/>
          </div>}
        </div>
      )
    }
}

export default App
